.servicesPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FAFAFA;
}

.servicesBox {
  width: 100%;
  height: 100%;
  background: #FAFAFA;
}

.servicesList {
  padding: 0;
  width: 100%;
  height: 95%;
  margin: 1.5% 0;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, 270px);
  column-gap: 1rem;
  row-gap: .5rem;
  list-style: none;
}

.servicesList li {
  border: 2px solid #000000;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  flex-direction: column;
  height: 7.5rem;
  background: #FFFFFF;
}

.price {
  display: none;
}