.container {
  display: grid;
  flex: 1;
  grid-template-columns: auto;
  grid-template-rows:  50px 50px auto 50px;
  grid-template-areas: 
    "modal"
    "header"
    "content"
    "footer";
};

.content {
  grid-area: content;
  display: flex;
}

.covidButton {
  margin-left: .65rem;
}