@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Open+Sans&display=swap");

body, html { 
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif !important;
  background-color: #FAFAFA;
}

#root {
  background-color: #FAFAFA;
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}