.contactPage {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactBox {
  grid-area: contactBox;
  width: 100%;
  display: flex;
  grid-template-columns: repeat(auto-fill, 270px);
  justify-content: center;
  column-gap: 1rem;
  row-gap: .5rem;
}

@media (max-width: 768px) {
  .contactBox {
    height: 95%;
  }
}

.contactCard {
  border: 2px solid #000000;
  height: 100%;
  width: 50%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-evenly;
  font-style: italic;
  font-weight: 300;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(0,0,0,.2);
}

.addressBlock, .phoneBlock, .emailBlock {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
}

.phoneBlock {
  margin-bottom: .5rem;
}

.addressBlock p {
  margin: 0;
}

@media (max-width: 768px) {
  .contactCard {
    flex-direction: column;
    width: 70%;
    padding: 2%;
  }
}

.cardTitle {
  border-bottom: 1px solid #000000;
  margin-bottom: 1.5rem;
}

.cardTitle h4 {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 300;
  margin: 0; 
  line-height: 1.5;
}