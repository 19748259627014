@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Open+Sans&display=swap");

.App {
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  row-gap: 2.5rem;
  grid-template-areas:
    "message"
    "contactBox";
}

.welcomeMessage {
  grid-area: message;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 80%;
  align-items: center;
  place-self: center;
  font-weight: 300;
  font-size: 1.25rem;
  text-align: center;
}

.name {
  font-weight: 300;
  line-height: 1.2;
  font-size: 4.5rem;
}

.top,
.bottom {
  width: 70%;
  padding: 1rem;
  margin: 0;
}

.top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.contactBox {
  grid-area: contactBox;
  width: 100%;
  display: flex;
  grid-template-columns: repeat(auto-fill, 270px);
  justify-content: center;
  column-gap: 1rem;
  row-gap: .5rem;
}

.contactCard {
  border: 2px solid #000000;
  height: 100%;
  width: 50%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-evenly;
  font-style: italic;
  font-weight: 300;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(0,0,0,.2);
}

.addressBlock, .phoneBlock, .emailBlock {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
}

.phoneBlock {
  margin-bottom: .5rem;
}

.addressBlock p {
  margin: 0;
}

@media (max-width: 768px) {
  .contactCard {
    flex-direction: column;
    width: 70%;
    padding: 2%;
  }

  .addressBlock, .phoneBlock {
    border-bottom: 1px solid #000000;
  }
}

.cardTitle {
  /* border-bottom: 1px solid #000000; */
  margin-bottom: 1.5rem;
}

.cardTitle h4 {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 300;
  margin: 0; 
  line-height: 1.5;
}